@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Inter:wght@100..900&display=swap');

body {
  background-image: url("../public/images/Background@1x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.App {
  display: flex;
  height: 100vh;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.error-wrapper {
  display: flex;
  width: 392px;
  padding: 39px 80px 20px 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.88);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  text-align: center;
  font-family: Inter;
}

.error-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  margin: auto;
}

.error-desc {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.error-btn {
  padding: 10px 22px 10px 15px;
  border-radius: 4px;
  background: #0968DB;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  outline: none;
  border: none;
  margin-top: 20px;
}

.play-btn {
  border-radius: 13px;
  border: 5px solid #FFF;
  color: #FFF;
  text-align: center;
  font-family: "Alfa Slab One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 5px;
  padding: 28px 36px;
  background-color: transparent;
  cursor: pointer;
}
.play-btn:active {
  background-color: #28a745; /* Change background color when pressed */
}

.bottom-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rds-logo {
  width: 227px;
  height: 68px;
}

.rvx-logo {
  width: 137px;
  height: 38px;
}

.game-desc {
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  width: 526px;
}


@media only screen and (min-width: 2560px) {
  body {
    background-image: url("../public/images/Background@2x.png");

  }
}